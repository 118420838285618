<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>账户列表</span>
			</div>
			<operate-search :isSuper="false" @search="search" placeholder="请输入账户名称">
				<template #operateButtons>
					<el-button type="primary" size="small" @click="showForm(-1)">新增账户</el-button>
				</template>
			</operate-search>
			<el-table ref="multipleTable" :data="adminList" border tooltip-effect="dark" class="mt-3">
				<el-table-column type="index" width="50" label="序号">
				</el-table-column>
				<!-- <el-table-column prop="avatar" label="头像" width="80">
					<template slot-scope="scope">
						<el-avatar size="large" :src="scope.row.avatar"></el-avatar>
					</template>
				</el-table-column> -->
				<el-table-column prop="username" label="账户" width="100">
				</el-table-column>
				<el-table-column prop="name" label="姓名">
				</el-table-column>
				<el-table-column prop="mobile" label="手机">
				</el-table-column>
				<el-table-column prop="work_id" label="工号">
				</el-table-column>
				<el-table-column prop="role.name" label="角色">
				</el-table-column>
				<el-table-column prop="user.nickName" label="微信">
				</el-table-column>
				<el-table-column prop="status" label="状态" width="100">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
							:active-value="1" :inactive-value="0" @change="changeStatus(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="operate" label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="showProductDialog(scope.$index)">绑定微信</el-button>
						<el-button type="text" size="mini" @click="showForm(scope.$index, scope.row)">修改</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">
							删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange"
				:current-page="pageData.current" :page-sizes="pageData.sizes" :page-size="pageData.size"
				layout="total, sizes, prev, pager, next, jumper" :total="pageData.total" class="mt-3">
			</el-pagination>
		</el-card>
		<el-dialog :title="formTitle" :visible.sync="formVisible">
			<el-form :model="formData" :rules="adminRules" ref="adminRuleForm">
				<el-form-item label="账户" :label-width="formLabelWidth" prop="username">
					<el-input v-model="formData.username" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="密码" :label-width="formLabelWidth" prop="password">
					<el-input v-model="formData.password" type="password" autocomplete="off" size="small" style="width: 80%;"
						:placeholder="editIndex >= 0 ? '如不修改，放空即可':''"></el-input>
				</el-form-item>
				<el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
					<el-input v-model="formData.name" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="手机" :label-width="formLabelWidth" prop="mobile">
					<el-input v-model="formData.mobile" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="工号" :label-width="formLabelWidth" prop="work_id">
					<el-input v-model="formData.work_id" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="角色" :label-width="formLabelWidth" prop="role_id">
					<el-select v-model="formData.role_id" placeholder="请选择角色" size="small">
						<el-option v-for="item in roleOptions" :label="item.name" :value="item.id" :key="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
		<product-dialog ref="productDialog" @selectConfirm="bindWeChat"></product-dialog>
	</div>
</template>

<script>
	import operateSearch from "@/components/common/operate-search.vue"
	import common from "@/common/mixins/common.js"
	import productDialog from '@/components/common/product-dialog.vue'
	export default {
		components: {
			operateSearch,productDialog
		},
		inject: ['layout'],
		mixins: [common],
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '' && this.editIndex == -1) {
					callback(new Error('请输入密码'));
				} else {
					callback();
				}
			};
			return {
				preUrl: 'admin',
				adminList: [],
				keyword: '',
				editIndex: -1,
				formTitle: '添加账户',
				formVisible: false,
				formData: {},
				formLabelWidth: '120px',
				roleOptions: [],
				selectIndex:'',
				adminRules: {
					username: [{
						required: true,
						message: '请输入账户名称',
						trigger: 'blur'
					}, ],
					password: [{
						validator: validatePass,
						trigger: 'blur'
					}, ],
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					work_id: [{
						required: true,
						message: '请输入工号',
						trigger: 'blur'
					}, ],
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}]
				}
			};
		},
		watch:{
			formVisible:function(){
				if(!this.formVisible){
					this.$refs['adminRuleForm'].resetFields();
				}
			}
		},
		methods: {
			getListRes(e) {
				this.adminList = e.list
				this.roleOptions = e.role
			},
			getUrl() {
				let url = `/admin/${this.preUrl}/index/${this.pageData.current}?limit=${this.pageData.size}`
				if (this.keyword !== '') {
					url += '&keyword=' + this.keyword
				}
				return url
			},
			showForm(index, row) {
				this.editIndex = index
				if (this.editIndex === -1) {
					this.formTitle = '添加账户'
					this.formData = {
						username: '',
						password: '',
						name: '',
						mobile: '',
						work_id: '',
						role_id: '',
						status: 1
					}
				} else {
					this.formTitle = '修改账户'
					console.log(row)
					this.formData = {
						username: row.username,
						password: row.password,
						name: row.name,
						mobile: row.mobile,
						work_id: row.work_id,
						role_id: row.role_id,
						status: row.status
					}
				}
				this.formVisible = true
			},
			submit() {
				this.$refs['adminRuleForm'].validate((valid) => {
					if (valid) {
						let id = 0
						if (this.editIndex !== -1) {
							id = this.adminList[this.editIndex].id
						}
						this.addOrEdit(this.formData, id)
						this.formVisible = false
					}
				})
			},
			search(e) {
				this.keyword = e
				this.getList()
			},
			bindWeChat(e) {
				console.log(e)
				let data = {
					admin_id:this.adminList[this.selectIndex].id,
					user_id:e.id
				}
				let url = '/admin/admin/bindwx'
				this.axios.post(url,data,{token:true})
				.then((res)=>{
					console.log(res)
					this.getList()
				})
				.catch((err) => {
					console.log(err)
				})
			},
			showProductDialog(index) {
				this.selectIndex = index
				this.$refs.productDialog.visibleDialog = true
				this.$refs.productDialog.getListData()
			},
		}
	};
</script>

<style>
</style>
